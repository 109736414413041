import React, { useEffect } from 'react';
import { Select } from '@cx/ui';
import { SelectedAdvancedFilter } from 'types';

export interface AttributeChoicesProps {
  filter: SelectedAdvancedFilter;
  index: number;
  onChange?: (option: any) => void;
  getAttributeChoices: (filter: SelectedAdvancedFilter, index: number) => void;
  isSearchable?: boolean;
  [rest: string]: any;
}

function AttributeChoices({
  className = '',
  filter,
  index,
  onChange,
  getAttributeChoices,
  selectedFilters,
  isSearchable = false,
}: AttributeChoicesProps) {
  useEffect(() => {
    getAttributeChoices(filter, index);
  }, []);
  const options = filter?.questionDetails?.attributeChoices?.Choices?.map(
    ({ Label: label, Value: value }: { Label: string; Value: string }) => ({
      label,
      value,
    })
  );
  const selectedOptions = selectedFilters?.[index]?.Values?.map(
    (label: string) => ({
      label,
      value: label,
    })
  );

  if (options) {
    return (
      <Select
        className={className}
        name="attributeChoices"
        isClearable={false}
        isSearchable={isSearchable}
        options={options}
        onChange={onChange}
        value={selectedOptions || null}
        defaultOptions={options}
        isMulti={filter?.OperatorOption?.value === 'IN'}
      />
    );
  }
  return null;
}

export default AttributeChoices;
