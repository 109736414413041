import { useEffect, useState } from 'react';
import { getUserToken } from '../services';

export const useUserToken = (): string | null => {
  const [token, setToken] = useState(null);

  const getToken = async () => {
    try {
      const userToken = await getUserToken();
      setToken(userToken);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getToken();
  }, []);

  return token;
};
