import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Modal from 'react-modal';
import { Calendar } from 'react-calendar';
import CalendarDateRangeLabel from 'core-ui/CalendarDateRangeLabel/CalendarDateRangeLabel';
import 'react-calendar/dist/Calendar.css';
import './DiyCalendarContainer.css';
import { getDateByPeriodByType } from 'helpers/getTimePeriod';

interface ModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onSelectDateRange: any;
  chartFilters: any;
  currentSelection: any;
}

const DiyCalendarContainer: React.FC<ModalProps> = ({
  isOpen,
  onRequestClose,
  chartFilters,
  onSelectDateRange,
  currentSelection,
}) => {
  const [dateHasChanged, setDateHasChanged] = useState<boolean>(false);
  const currentDate =
    Object.keys(currentSelection).length !== 0 &&
    currentSelection.DateFilter !== null &&
    currentSelection.DateFilter !== undefined
      ? new Date(currentSelection.DateFilter.EndDate)
      : new Date();
  const initialStartDate =
    Object.keys(currentSelection).length !== 0 &&
    currentSelection.DateFilter !== null &&
    currentSelection.DateFilter !== undefined
      ? new Date(currentSelection.DateFilter.StartDate)
      : new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  const selectOptions = chartFilters.map((item: any) => {
    // Check if the item is editable
    if (item.IsEditable) {
      // Modify the StartDate if editable
      const modifiedStartDate = new Date();
      return {
        label: item.DisplayName,
        value: {
          ...item,
          StartDate: modifiedStartDate.toLocaleDateString('en-US', {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
          }), // Update the StartDate
        },
      };
    } else {
      // Return the original item if not editable
      return {
        label: item.DisplayName,
        value: item,
      };
    }
  });

  const defaultValue =
    Object.keys(currentSelection).length !== 0 &&
    currentSelection.DateFilter !== null &&
    currentSelection.DateFilter !== undefined
      ? selectOptions.find(
          (o: any) => o.value.DisplayName === currentSelection.DateFilter.DisplayName
        )
      : selectOptions.find((o: any) => o.value.DisplayName === 'Month To Date');

  const [startSelectedDate, setStartSelectedDate] = useState(initialStartDate);
  const [endSelectedDate, setEndSelectedDate] = useState(currentDate);

  const optionItem =
    Object.keys(currentSelection).length !== 0
      ? selectOptions.find(
          (o: any) =>
            o.value &&
            o.value.DisplayName &&
            currentSelection.DateFilter &&
            currentSelection.DateFilter.DisplayName &&
            o.value.DisplayName === currentSelection.DateFilter.DisplayName
        )
      : null;

  const [selectedDateOption, setSelectedDateOption] = useState<any>(optionItem || null);

  useEffect(() => {
    setSelectedDateOption(optionItem);
  }, []);

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      border: '1px solid #d1f0ee',
      borderRadius: '4px',
      boxShadow: state.isFocused ? '0 0 0 1px #d1f0ee' : null,
      '&:hover': {
        border: '1px solid #d1f0ee',
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      background: state.isSelected ? '#76D1CB' : 'white',
      color: state.isSelected ? 'white' : '#76D1CB',
      '&:hover': {
        background: '#76D1CB',
        color: 'white',
      },
    }),
  };

  const handleDateChange = (date: any) => {
    // Update the state to reflect the selected range
    if (
      (Object.keys(currentSelection).length !== 0 &&
        currentSelection.DateFilter.DisplayName === 'Custom Range') ||
      selectedDateOption.label === 'Custom Range'
    ) {
      setSelectedDateOption(selectedDateOption || optionItem);
      setStartSelectedDate(date[0]);
      setEndSelectedDate(date[1]);
    }
  };

  const dateRangeChange = (date: any) => {
    setSelectedDateOption(date);
    setStartSelectedDate(new Date(date.value.StartDate));
    setEndSelectedDate(new Date(date.value.EndDate));
    setDateHasChanged(true);
  };

  // send selection to drawer
  const handleOk = () => {
    setSelectedDateOption(selectedDateOption == undefined ? currentSelection : selectedDateOption);
    const customObj = selectedDateOption;
    if (selectedDateOption) {
      if (selectedDateOption.value.IsEditable) {
        customObj.value.StartDate = startSelectedDate.toLocaleDateString('en-US', {
          month: 'numeric',
          day: 'numeric',
          year: 'numeric',
        });
        customObj.value.EndDate = endSelectedDate.toLocaleDateString('en-US', {
          month: 'numeric',
          day: 'numeric',
          year: 'numeric',
        });
      }
    }
    onSelectDateRange(selectedDateOption || defaultValue);
    onRequestClose();
  };

  const formatNavigationLabel = () => {
    // check if date type has not changed and render calendar header label
    if (
      !dateHasChanged &&
      currentSelection.DateFilter &&
      currentSelection?.DateFilter?.Type.toString() !== 'CustomRange'
    ) {
      const updateSelectedDates = getDateByPeriodByType(currentSelection.DateFilter.Type);
      const updatedStartDate = new Date(updateSelectedDates.split('-')[0].replace(/ /g, ''));
      const updatedEndDate = new Date(updateSelectedDates.split('-')[1].replace(/ /g, ''));
      return <CalendarDateRangeLabel startDate={updatedStartDate} endDate={updatedEndDate} />;
    }
    // check if date type has changed and render calendar header label
    return <CalendarDateRangeLabel startDate={startSelectedDate} endDate={endSelectedDate} />;
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Modal"
        className="custom-modal"
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        overlayClassName="custom-overlay"
      >
        <div style={{ display: 'flex' }}>
          <div className="columnCalendarContainer">
            <div className="dropDownDateRange">
              <Select
                className="diy-select"
                onChange={dateRangeChange}
                options={selectOptions}
                value={selectedDateOption != undefined ? selectedDateOption : defaultValue}
                styles={customStyles}
              />
            </div>
          </div>
          <div className="columnCalendarContainer">
            <div style={{ marginBottom: '10px', textAlign: 'center' }}>
              {formatNavigationLabel()}
            </div>
            <Calendar
              onChange={handleDateChange}
              selectRange={true}
              value={[startSelectedDate, endSelectedDate]}
              showDoubleView={true}
              tileClassName={'diyCalendarTile'}
            />
          </div>
        </div>
        <div className="diy-calendar-buttons">
          <button className="diy-button diy-button-enabled-inverse" onClick={onRequestClose}>
            Cancel
          </button>
          <button
            className="diy-button diy-button-enabled-inverse"
            style={{ marginLeft: '45px' }}
            onClick={handleOk}
          >
            Apply
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default DiyCalendarContainer;
