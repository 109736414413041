import React, { useEffect, useState } from 'react';
import { BaseWidget } from 'models/WidgetModel';
import './SessionExplorerContainer.scss';
import ChatTranscript from '../ChatTranscript/ChatTranscript';
import SessionExplorer from 'components/SessionExplorer/SessionExplorer';

const SessionExplorerContainer: React.FC<BaseWidget> = (response) => {
  const [sessionSelected, setSessionSelected] = useState(null);
  const [openTranscript, setOpenTranscript] = useState(false);

  useEffect(() => {
    if (sessionSelected) {
      setOpenTranscript(true);
    }
  }, [sessionSelected]);

  useEffect(() => {
    if (openTranscript === false) {
      setSessionSelected(null);
    }
  }, [openTranscript]);

  return (
    <>
      <div className="session-explorer-container">
        <div className="grid">
          <SessionExplorer
            response={response}
            sessionClick={setSessionSelected}
            hideTranscript={openTranscript}
          />
        </div>
        {openTranscript ? (
          <div className="transcript">
            <ChatTranscript data={sessionSelected} setOpenTranscript={setOpenTranscript} />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default SessionExplorerContainer;
