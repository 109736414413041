import * as XLSX from 'xlsx';
import { boldNumbersInString } from 'core-ui/BoldNumberInString/boldNumbersInString';
import { DateCustomPeriodTypeFormat } from 'helpers/dateFormattingHelper';
import { getHierarchyFiltersLabelData, getAdvancedFiltersLabelData } from 'helpers/utilities';
import { getDateByPeriodByType } from 'helpers/getTimePeriod';

// Object with label for hierarchy, advanced and calendar filters
interface FiltersLabel {
  hierarchyFiltersLabel: [];
  advancedFiltersLabel: [];
  label: string;
}

export const exportToExcel = (
  tableData: any,
  title: string,
  showTotals: boolean,
  customWidgetConfig: any
) => {
  // Get label for hierarchy, advanced and calendar filters
  const obj: FiltersLabel | undefined = getFiltersLabel(customWidgetConfig);
  const wb = XLSX.utils.book_new();

  const csvData = getDataToExport(
    tableData,
    title,
    showTotals,
    obj?.hierarchyFiltersLabel ? obj?.hierarchyFiltersLabel : [],
    obj?.advancedFiltersLabel ? obj?.advancedFiltersLabel : [],
    obj?.label ? obj?.label : ''
  );

  const ws = XLSX.utils.aoa_to_sheet(csvData);
  ws['!cols'] = getMaxWidth(csvData);
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  XLSX.writeFile(wb, 'data.xlsx');
};

// Method to return label for selected filters
const getFiltersLabel = (customWidgetConfig: any): FiltersLabel | undefined => {
  let label = '';
  let hierarchyFiltersLabel = [];
  let advancedFiltersLabel = [];
  let timePeriodType = '';
  if (customWidgetConfig?.Filters != null) {
    if (customWidgetConfig?.Trend.length === 0) {
      const shortLabel = customWidgetConfig?.Filters?.CurrentSelection?.DateFilter;
      timePeriodType = customWidgetConfig?.Filters?.CurrentSelection?.DateFilter?.Type;
      const startDate = DateCustomPeriodTypeFormat(
        shortLabel?.StartDate ? new Date(shortLabel.StartDate) : new Date()
      );
      const endDate = DateCustomPeriodTypeFormat(
        shortLabel?.EndDate ? new Date(shortLabel?.EndDate) : new Date()
      );

      label = `${shortLabel?.DisplayName}: ${startDate} - ${endDate}`;
      if (timePeriodType === 'CustomRange') {
        label = `${shortLabel?.DisplayName}: ${startDate} - ${endDate}`;
      } else {
        label = `${shortLabel?.DisplayName}: ${getDateByPeriodByType(timePeriodType)}`;
      }
    }

    // Get Hierarchy Filters and Advanced Filters Label
    const { HierarchyFilters, AdvancedFilters } =
      customWidgetConfig.Filters?.CurrentSelection || {};

    hierarchyFiltersLabel = getHierarchyFiltersLabelData(HierarchyFilters);
    advancedFiltersLabel = getAdvancedFiltersLabelData(AdvancedFilters);
    return {
      hierarchyFiltersLabel,
      advancedFiltersLabel,
      label,
    };
  }
};

export const getDataToExport = (
  tableData: any,
  title: string,
  showTotals: boolean,
  hierarchyFiltersLabel: [],
  advancedFiltersLabel: [],
  dateRangeFilter: any
) => {
  const temp = tableData?.columns || [];
  const columns = temp.map((column: any) => ({
    label: column.name && column.name.props && column.name.props.text ? column.name.props.text : '',
    key: column.sortField ? column.sortField : '',
  }));

  const data = tableData?.data || [];

  // Prepare CSV data with titles
  const dataToExport = [
    [title],
    [tableData?.rawData?.subTitle || ''],
    [dateRangeFilter || ''],
    [hierarchyFiltersLabel.join('\n') || ''],
    [advancedFiltersLabel?.join('\n') || '' || ''],
    [boldNumbersInString(tableData?.rawData?.baseSize ?? '', showTotals, true) || ''],
    columns.map((column: any) => column.label), // Headers
    ...data.map((item: any) =>
      columns.map((column: any) => (item[column.key] !== undefined ? item[column.key] : ''))
    ),
  ];

  return dataToExport;
};

// Function to calculate the maximum width of the content in each column
const getMaxWidth = (data: any) => {
  const maxWidths: any = [];

  data.forEach((row: any) => {
    row.forEach((cell: any, colIndex: any) => {
      if (cell !== undefined) {
        const cellWidth = cell.toString().length;
        maxWidths[colIndex] = Math.max(maxWidths[colIndex] || 0, cellWidth);
      }
    });
  });

  return maxWidths.map((width: any) => ({ wch: width + 2 })); // Add some padding
};

export const convertToCSV = (
  table: any,
  title: string,
  showTotals: boolean,
  customWidgetConfig: any
) => {
  // Get label for hierarchy, advanced and calendar filters
  const obj: FiltersLabel | undefined = getFiltersLabel(customWidgetConfig);
  const csvData = getDataToExport(
    table,
    title,
    showTotals,
    obj?.hierarchyFiltersLabel ? obj?.hierarchyFiltersLabel : [],
    obj?.advancedFiltersLabel ? obj?.advancedFiltersLabel : [],
    obj?.label ? obj?.label : ''
  );
  const csvRows = [];
  for (const row of csvData) {
    const csvColumns = row.map((value: any) => `"${value}"`);
    csvRows.push(csvColumns.join(','));
  }
  return csvRows.join('\n');
};

const filterDataFields = (data: any[], fieldsToRemove: any[]) => {
  return data.map((entry) => {
    fieldsToRemove.forEach((field) => {
      delete entry[field];
    });
    return entry;
  });
};

export const removeOmittedColumns = (dataObj: any) => {
  // Step 1: Filter out the columns with omit = true
  const columnsToKeep = dataObj.columns.filter((column: { omit: any }) => !column.omit);

  // Step 2: Identify the sortField values of columns with omit = true
  const sortFieldsToRemove = dataObj.columns
    .filter((column: { omit: any }) => column.omit)
    .map((column: { sortField: any }) => column.sortField);

  // Filter rawData columns
  const rawColumnsToKeep = dataObj.rawData.columns.filter(
    (column: { field: any }) => !sortFieldsToRemove.includes(column.field)
  );

  // Filter rawData data
  const filteredRawData = filterDataFields(dataObj.rawData.data, sortFieldsToRemove);

  // Filter main data
  const filteredMainData = filterDataFields(dataObj.data, sortFieldsToRemove);

  // Update the object with filtered data
  dataObj.columns = columnsToKeep;
  dataObj.rawData.columns = rawColumnsToKeep;
  dataObj.rawData.data = filteredRawData;
  dataObj.data = filteredMainData;

  return dataObj;
};
