import { legendCommon, legendTextStyle, tooltip } from 'constants/chartOption';
import { eyeIcon } from 'constants/assets';

export const commetChartOptions = (...args: any) => {
  const [dataSet, positiveChangeData, negativeChangeData, xAxisData, colors, maxValue] = args;
  return {
    grid: {
      left: '32',
      right: '15',
      top: '35',
      bottom: '60',
    },
    legend: {
      ...legendCommon,
      textStyle: {
        ...legendTextStyle,
      },
      selector: [
        {
          type: 'all',
          title: '{icon|}{a|Show All}',
        },
      ],
      selectorLabel: {
        show: true,
        rich: {
          a: {
            fontSize: 11,
          },
          icon: {
            width: 30,
            backgroundColor: {
              image: eyeIcon,
            },
          },
        },
        borderWidth: 0,
      },
      emphasis: {
        selectorLabel: {
          color: '#8a8a8a',
          backgroundColor: 'transparent',
        },
      },
      selectorPosition: 'end',
      selectedMode: true,
      show: true,
    },
    dataset: [
      {
        source: dataSet,
      },
      {
        transform: {
          type: 'filter',
          config: { dimension: 'net_change', '>=': 0 },
        },
      },
      {
        transform: {
          type: 'filter',
          config: { dimension: 'net_change', '<': 0 },
        },
      },
    ],
    series: [
      {
        type: 'scatter',
        name: 'Positive Change',
        datasetIndex: 1,
        color: colors[0],
        symbolSize: 15,
        encode: {
          x: 'category',
          y: 'value',
        },
      },
      {
        type: 'scatter',
        name: 'Positive Change',
        datasetIndex: 1,
        color: '#B3B3B3',
        symbolSize: 8,
        encode: {
          x: 'category',
          y: 'previous_value',
        },
      },
      {
        type: 'scatter',
        name: 'Negative Change',
        datasetIndex: 2,
        color: colors[1],
        symbolSize: 15,
        encode: {
          x: 'category',
          y: 'value',
        },
      },
      {
        type: 'scatter',
        name: 'Negative Change',
        datasetIndex: 2,
        color: '#B3B3B3',
        symbolSize: 8,
        encode: {
          x: 'category',
          y: 'previous_value',
        },
      },
      {
        type: 'pictorialBar',
        name: 'Positive Change',
        datasetIndex: 0,
        symbol: 'path://M 17.5 0 L 32.5 0 L 29 25 L 21 25 Z',
        color: '#D6D6D6',
        barWidth: 15,
        data: positiveChangeData,
      },
      {
        type: 'pictorialBar',
        name: 'Negative Change',
        datasetIndex: 0,
        symbol: 'path://M 17.5 0 L 32.5 0 L 29 25 L 21 25 Z',
        color: '#D6D6D6',
        barWidth: 15,
        data: negativeChangeData,
      },
    ],
    xAxis: {
      type: 'category',
      max: maxValue,
      data: xAxisData,
    },
    yAxis: {
      type: 'value',
      name: 'Value',
      max: 3,
      min: -3,
    },
    tooltip: {
      ...tooltip,
      trigger: 'axis',
    },
  };
};
