import { appAssets } from 'constants/assets';
import React, { useEffect } from 'react';
import styles from '../CardWidget/CardWidget.module.scss';
import { Widget } from 'models/WidgetModel';
import CardTitle from '../CardTitle/CardTitle';
import CardHeaderActions from '../CardHeaderActions/CardHeaderActions';
import DataNotFound from 'components/DataNotFound/DataNotFound';
import LoadingSpinner from 'core-ui/LoadingSpinner/LoadingSpinner';
import { useStore } from 'store/useGlobalStore';
import libraryComponents from 'components';
import { showWarningValidation, warningValidData } from 'utils/validators/validatorDataList';
import DataTimeframe from '../DataTimeframe/DataTimeframe';
import { useWidgetContext } from 'contexts/WidgetProvider';
import { DateCustomPeriodTypeFormat } from 'helpers/dateFormattingHelper';
import { getHierarchyFiltersLabelData, getAdvancedFiltersLabelData } from 'helpers/utilities';
import { getDateByPeriodByType } from 'helpers/getTimePeriod';
import CardFooterActions from 'components/CardFooterActions/CardFooterActions';

interface WidgetToRenderProps {
  removeWidget?: (id: number) => void;
  editWidget: (widget: any) => void;
  widget: Widget;
  validateData: (res: any) => boolean;
  response: any;
  index: number;
  loading: boolean;
  error: boolean;
  dataSourceType: any;
  customWidgetConfig: any;
  previewMode: boolean;
}

const WidgetToRender: React.FC<WidgetToRenderProps> = ({
  removeWidget,
  editWidget,
  widget,
  validateData,
  response,
  index,
  loading,
  error,
  dataSourceType,
  customWidgetConfig,
  previewMode,
}) => {
  const { displayName, options, viewName, isCustom } = widget;
  const showCardClass = () => (options?.showCard ? styles[`card`] : styles[`no-card`]);
  const DeleteWidget = appAssets.icons.DELETE_ICON;
  const EditWidget = appAssets.icons.EDIT_ICON;
  const editing = useStore((state) => state.editing);
  const metricWidgets = ['IndividualMetric', 'MultipleSummaryMetrics'];
  const ChildComponent = libraryComponents[widget.alias];
  const { setState } = useWidgetContext();
  const hasTrend = widget.customWidgetConfig?.Trend?.length;
  const isTextBlock = widget.alias === 'TextBlock';
  useEffect(() => {
    setState({
      defaults: {
        ...('defaults' in options ? { ...options.defaults } : {}),
      },
    });
  }, [options]);

  let label = '';
  let hierarchyFiltersLabel = [];
  let advancedFiltersLabel = [];

  if (widget.customWidgetConfig?.Filters != null) {
    const shortLabel = widget.customWidgetConfig?.Filters?.CurrentSelection?.DateFilter;
    const timePeriodType = widget.customWidgetConfig?.Filters?.CurrentSelection?.DateFilter?.Type;
    const startDate = DateCustomPeriodTypeFormat(
      shortLabel?.StartDate ? new Date(shortLabel.StartDate) : new Date()
    );
    const endDate = DateCustomPeriodTypeFormat(
      shortLabel?.EndDate ? new Date(shortLabel?.EndDate) : new Date()
    );
    label = shortLabel?.DisplayName + ': ' + startDate + ' - ' + endDate;

    // Hierarchy Filters Label
    const selectedHierarchyFilters =
      widget.customWidgetConfig.Filters?.CurrentSelection?.HierarchyFilters;

    // Advanced Filters
    const selectedAdvancedFilters =
      widget.customWidgetConfig.Filters?.CurrentSelection?.AdvancedFilters;

    hierarchyFiltersLabel = getHierarchyFiltersLabelData(selectedHierarchyFilters);
    advancedFiltersLabel = getAdvancedFiltersLabelData(selectedAdvancedFilters);
    if (timePeriodType === 'CustomRange') {
      label = shortLabel?.DisplayName + ': ' + startDate + ' - ' + endDate;
    } else {
      label = shortLabel?.DisplayName + ': ' + getDateByPeriodByType(timePeriodType);
    }
  }

  return (
    <>
      {!loading ? (
        <div
          className={`${showCardClass()} 
            ${metricWidgets.includes(widget.alias) ? styles[`metric-card`] : null}`}
        >
          {editing && removeWidget ? (
            <div className={styles[`cardOverlay`]}>
              <div className={styles[`cardActions`]}>
                {widget?.isCustom && widget.payload?.QueriesMetadata == null ? (
                  <EditWidget className={styles[`cardAction`]} onClick={() => editWidget(widget)} />
                ) : null}
                <DeleteWidget
                  className={styles[`cardAction`]}
                  onClick={() => removeWidget(parseInt(widget.config.i))}
                />
              </div>
            </div>
          ) : null}
          {options?.showTitle || options?.showActions ? (
            <div className={styles[`cardHeader`]}>
              {options?.showTitle && displayName?.length > 0 ? (
                <CardTitle
                  displayName={displayName}
                  viewName={widget.isCustom ? '' : viewName}
                  isMetricWidget={metricWidgets.includes(widget.alias)}
                  showWarning={showWarningValidation(
                    previewMode,
                    widget?.isMockData || false,
                    widget.alias
                  )}
                  warningValidData={warningValidData(response, widget.alias)}
                  isActions={options?.showActions}
                />
              ) : null}
              <div className={styles[`card-info-actions`]}>
                <CardHeaderActions {...{ actions: options?.actions, response }} />
                {widget.isCustom &&
                  !isTextBlock &&
                  (hasTrend === 0 ||
                    hasTrend === undefined ||
                    widget.customWidgetConfig?.Filters?.CurrentSelection?.HierarchyFilters ||
                    widget.customWidgetConfig?.Filters?.CurrentSelection?.AdvancedFilters) && (
                    <DataTimeframe
                      timePeriodType={label}
                      hierarchyFiltersValues={hierarchyFiltersLabel}
                      advancedFiltersValues={advancedFiltersLabel}
                      hasTrend={hasTrend !== 0 ? true : false}
                      hasQuery={widget.payload?.QueriesMetadata !== null ? true : false}
                    />
                  )}
              </div>
            </div>
          ) : null}
          {validateData(response) || customWidgetConfig?.DataSource === 'generic' ? (
            <ChildComponent
              {...widget}
              id={index}
              response={response}
              loading={loading}
              error={error}
              dataSourceType={dataSourceType}
              previewMode={previewMode}
            />
          ) : (
            <DataNotFound />
          )}
          {<CardFooterActions {...{ actions: options?.actions, response, isCustom }} />}
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export default WidgetToRender;
