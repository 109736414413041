import React, { useEffect, useMemo } from 'react';
import { styled } from 'theme';
import Text from '@cx/ui/components/Text';
import { useAppActions, useAppState } from 'store';
import {
  AdvancedFilterData,
  AdvancedFilterType,
  SelectedAdvancedFilter,
} from 'types';
import selectors from 'store/selectors';
import AddAdvancedFilterButton from './AddAdvancedFilterButton';
import AdvancedFilter, { AdvancedFilterProps } from './AdvancedFilter';

const adaptFilters = (filters: AdvancedFilterType[]) =>
  filters?.map?.((filter) => ({
    label: filter.Label,
    value: filter.AttributeGUID,
    filter,
  }));

// Function to create an unique key for the advanced filter
const getAdvancedFilterKey = ({
  filter,
  index,
}: {
  filter: SelectedAdvancedFilter;
  index: number;
}) =>
  `advanced-filters-${filter?.Name || ''}-${filter?.AttributeGUID || ''}-${filter?.id}-${index}`;

export interface AdvancedFiltersProps
  extends Pick<
    AdvancedFilterProps,
    | 'onOperationOptionSelected'
    | 'onAttributeChoicesSelected'
    | 'onTextBoxChange'
    | 'areAttributesSearchable'
  > {
  onAdvancedFiltersChange?: (state: AdvancedFilterData) => void;
  onAdvancedFilterAdded?: () => void;
  onAdvancedFilterSelected?: (filter: any, index: number) => void;
  onAdvancedFilterRemoved?: (filter: any, index: number) => void;
  onAddAdvancedFilterInitiated?: () => void;
  addButtonLabel?: string;
}

/**
 * @name AdvancedFilters
 * @descirption Component that renders advanced filters
 * @returns {Component}
 */

function AdvancedFilters({
  className = '',
  onAdvancedFiltersChange = () => {},
  onAdvancedFilterAdded = () => {},
  onAdvancedFilterSelected = () => {},
  onAdvancedFilterRemoved = () => {},
  onOperationOptionSelected = () => {},
  onAttributeChoicesSelected = () => {},
  onTextBoxChange = () => {},
  onAddAdvancedFilterInitiated = () => {},
  addButtonLabel = 'Add Advanced Filter',
  areAttributesSearchable = false,
}: AdvancedFiltersProps & React.HTMLAttributes<HTMLDivElement>) {
  const {
    addAdvancedFilter,
    removeAdvancedFilter,
    getAttributeChoices,
    getAdvancedFiltersQuestionList,
    initAdvancedFilters,
  } = useAppActions();
  const advancedFilters = useAppState(selectors.advanced.getSelectedFilters);
  const AdvancedFiltersGUID = useAppState(
    selectors.advanced.getAdvanceFiltersGuid
  );
  const filters = useAppState(selectors.advanced.getFilters);
  const options = useMemo(() => adaptFilters(filters), [filters]);
  // We need to stringify the advanced filters to compare them in the useEffect
  const jsonAdvancedFilters = JSON.stringify(advancedFilters);

  useEffect(() => {
    getAdvancedFiltersQuestionList();
  }, []);
  useEffect(() => {
    onAdvancedFiltersChange?.({
      AdvancedFiltersGUID,
      SelectedFilters: advancedFilters,
      Filters: filters,
    });
  }, [jsonAdvancedFilters, filters, AdvancedFiltersGUID]);

  /**
   * The first time we add a new advanced filter this will be empty
   */
  const handleAdvancedFilter = () => {
    addAdvancedFilter({}, advancedFilters?.length || 0);
    onAdvancedFilterAdded?.();
  };

  const handleRemoveFilter = (
    filter: SelectedAdvancedFilter,
    index: number
  ) => {
    removeAdvancedFilter(filter, index);
    onAdvancedFilterRemoved?.(filter, index);
  };
  const handleSelectFilter = (filter: any, index: number) => {
    addAdvancedFilter?.(filter, index);
    onAdvancedFilterSelected?.(filter, index);
  };

  useEffect(() => {
    initAdvancedFilters?.(onAddAdvancedFilterInitiated);
  }, []);

  return (
    <div
      data-testid="advanced-filter-dropdown"
      className={`${className} advanced-filter-dropdown`}
    >
      {advancedFilters?.map((_, index) => (
        <AdvancedFilter
          key={getAdvancedFilterKey({ filter: advancedFilters[index], index })}
          index={index}
          options={options}
          onRemoveAdvancedFilter={handleRemoveFilter}
          onSelectFilter={handleSelectFilter}
          filter={advancedFilters[index]}
          getAttributeChoices={getAttributeChoices}
          selectedFilters={advancedFilters}
          onOperationOptionSelected={onOperationOptionSelected}
          onAttributeChoicesSelected={onAttributeChoicesSelected}
          onTextBoxChange={onTextBoxChange}
          areAttributesSearchable={areAttributesSearchable}
        />
      ))}

      <AddAdvancedFilterButton
        className="advanced-filter-button"
        onClick={handleAdvancedFilter}
        disabled={advancedFilters?.length >= 10}
        selectedFilters={advancedFilters}
        label={addButtonLabel}
      />
      {
        // We limit the number of advanced filters to 10
        advancedFilters?.length >= 10 ? (
          <Text
            data-testid="addvanced-filter-error-message"
            className="cx-advanced-filters-error-message cx2-lang"
          >
            No more than 10 Advanced Filter Queries can be added at once{' '}
          </Text>
        ) : null
      }
    </div>
  );
}

export default styled(AdvancedFilters)((props) => ({
  '.cx-advanced-filters-error-message': {
    ...props.theme.typography.smallMedium,
    color: props.theme.palette.warning.main,
    marginTop: props.theme.spacing(0.5),
  },
}));
