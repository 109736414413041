import React from 'react';
import { AsyncSelect, type AsyncSelectProps } from '@cx/ui';
import { HierarchyFilter } from 'types';
import { styled } from 'theme';

export interface HierarchyFilterDropdownProps {
  hierarchyFilter: HierarchyFilter;
  selectedFilters: {
    [key: string]: { filter: HierarchyFilter; values: string[] };
  };
  className?: string;
  isLoading: boolean;
  onSelectHierarchyFilter?: (
    hierarchyFilter: HierarchyFilter,
    values: string[]
  ) => void;
  onFocusHierarchyFilter?: (hierarchyFilter?: HierarchyFilter) => void;
  onLoadOptions?: (
    inputValue: string,
    hierarchyFilter?: HierarchyFilter
  ) => void;
  selectProps?: Pick<AsyncSelectProps, 'labelTextClassName'>;
}

function HierarchyFilterDropdown({
  hierarchyFilter,
  className = '',
  selectedFilters = {},
  isLoading,
  onSelectHierarchyFilter,
  onFocusHierarchyFilter,
  onLoadOptions,
  selectProps,
}: HierarchyFilterDropdownProps) {
  const { Label, Choices } = hierarchyFilter;

  const currentValue =
    selectedFilters?.[hierarchyFilter.AttributeName]?.values?.map((value) => ({
      value,
      label: value,
    })) || null;

  const options = Choices?.map((item) => ({
    label: item.Label,
    value: item.Value,
  }));

  const handleChange = (items: any) => {
    const values: string[] = items.map(({ value }: any) => value);
    onSelectHierarchyFilter?.(hierarchyFilter, values);
  };

  const handleFocus = () => onFocusHierarchyFilter?.(hierarchyFilter);
  const handleLoadOptions = (inputValue: string) =>
    onLoadOptions?.(inputValue, hierarchyFilter);

  return (
    <div
      data-testid="cx-hierarchy-filter"
      className={`${className} cx-hierarchy-filter`}
    >
      <AsyncSelect
        {...(selectProps || {})}
        name="hierarchy-filter"
        data-test
        className="cx-hierarchy-filter__select"
        defaultOptions={options}
        options={options}
        value={currentValue}
        onChange={handleChange}
        label={Label}
        onFocus={handleFocus}
        isLoading={isLoading}
        loadOptions={handleLoadOptions}
        autoload={false}
        isMulti
        maxMenuHeight={250}
        closeMenuOnSelect={false}
      />
    </div>
  );
}

export default styled(HierarchyFilterDropdown)((props) => ({
  padding: props.theme.spacing(1, 0),
}));
