import React, { FC, useEffect, useState } from 'react';
import ReactECharts, { EChartsOption } from 'echarts-for-react';
import { BaseWidget } from 'models/WidgetModel';

interface GaugeChartProps extends BaseWidget {
  colors: string[];
  width?: any;
  height?: any;
}

const GaugeChart: FC<GaugeChartProps> = ({ response, colors, ...props }) => {
  const { width, height } = props;
  const [value, setValue] = React.useState<number>(0);
  const [isPercentage, setIsPercentage] = React.useState<boolean>(false);
  const [options, setOptions] = useState<any>();

  useEffect(() => {
    if (response) {
      setValue(response.data.data[0].value);
    }
    if (response?.data?.isPercentage) {
      setIsPercentage(response.data.isPercentage);
    }
  }, [response]);

  useEffect(() => {
    const optObj: EChartsOption = initOptions();
    setOptions(optObj);
  }, [value, isPercentage]);

  const initOptions = () => {
    return {
      series: [
        {
          name: 'Business Metric',
          type: 'gauge',
          startAngle: 180,
          endAngle: 0,
          axisLine: {
            lineStyle: {
              color: [
                [0.3, colors[0]],
                [0.7, colors[1]],
                [1, colors[2]],
              ],
              width: 16,
            },
          },
          axisLabel: {
            show: false,
          },
          detail: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          pointer: {
            length: '50%',
            width: 3,
            itemStyle: {
              color: '#9a9a9a',
            },
            offsetCenter: [0, '-15%'],
          },
          data: [{ value: isPercentage ? value * 100 : value }],
        },
      ],
    };
  };

  return options ? (
    <>
      <div className="responsive-chart">
        <ReactECharts opts={{ renderer: 'svg', width, height }} option={options} />
      </div>
    </>
  ) : null;
};

export default GaugeChart;
