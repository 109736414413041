/**
 * @remarks
 * Selectors are a series of function that accept the store state as an argument and returns
 * data based on that state.
 */
import { FilterState, TimePeriodTypeEnum } from 'types';
import { DASHBOARD_WITH_QUICKDATE_BUTTONS_DISABLED } from 'src/constants';
import { getFilterBarData } from 'utils/filterBarHelpers';
import hierarchy from './hierarchy';
import advanced from './advanced';
import calendar from './calendar';

const getViewId = (state: FilterState) => Number(state.ViewId);
const getWorkspaceId = (state: FilterState): string => state.WorkspaceId;
const getControllerName = (state: FilterState): string => state.ControllerName;
const getViewActionName = (state: FilterState): string => state.ViewActionName;
const getClientId = (state: FilterState): string => state.ClientId;
const getTransactionDateLabel = (state: FilterState): string =>
  state?.calendar?.data?.TransactionDateLabel;

const getSurveyDateLabel = (state: FilterState): string =>
  state?.calendar?.data?.SurveyDateLabel;

const getHasTransactionDate = (state: FilterState): boolean =>
  state?.calendar?.data?.HasTransactionDate;
const getIsYtd = (state: FilterState): boolean => state.IsYtd;

const getAreThereFiltersSelcted = (state: FilterState) => {
  const hierarchyFilters = hierarchy.getSelectedFilters(state);
  const advancedFilters = advanced.getSelectedFilters(state);
  const selectedFilterDate = calendar.getSelectedFilters(state);
  // Selected Filters in Hiararchy is an object
  const hierarchyFiltersArray = Object.keys(hierarchyFilters || {});

  // We only keep the filters that has info.
  // when the user add a filter the first time is empty
  const advancedFiltersWithData = advancedFilters?.filter(
    (item) => item?.AttributeGUID
  );

  return (
    [hierarchyFiltersArray, advancedFiltersWithData].some(
      (selectedFilters) => selectedFilters?.length > 0
    ) || !!(selectedFilterDate?.startDate || selectedFilterDate?.endDate)
  );
};

const getWeight = (state: FilterState) => state?.Weight || 0;
const getIsQuickDateButtonsDisabled = (state: FilterState): boolean =>
  state?.ControllerName
    ? !!DASHBOARD_WITH_QUICKDATE_BUTTONS_DISABLED[state?.ControllerName]
    : true;

const getTimePeriodType = (
  state: FilterState
): TimePeriodTypeEnum | undefined => state?.TimePeriodType;

const getQuickDateButtons = (state: FilterState) => state?.quickDateButtons;

const getFilterBarValues = (
  state: FilterState,
  keysToExtractByTagType: { [key: string]: string[] } = {}
) => getFilterBarData(state, keysToExtractByTagType);

export default {
  getViewId,
  getWorkspaceId,
  getControllerName,
  getTransactionDateLabel,
  getSurveyDateLabel,
  getHasTransactionDate,
  getIsYtd,
  getAreThereFiltersSelcted,
  getWeight,
  getIsQuickDateButtonsDisabled,
  getTimePeriodType,
  getViewActionName,
  getQuickDateButtons,
  getClientId,
  getFilterBarValues,
  hierarchy,
  advanced,
  calendar,
};
