import { IPublicClientApplication } from '@azure/msal-browser';

export const acquireAccessToken = async (
  msalInstance: IPublicClientApplication,
  scopes: Array<string>
) => {
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();
  if ((!activeAccount && accounts.length === 0) || msalInstance === null) {
    throw Error('Authentication failed');
  }
  const request = {
    scopes,
    account: activeAccount || accounts[0],
  };
  const authResult = await msalInstance.acquireTokenSilent(request);

  return authResult.accessToken;
};
