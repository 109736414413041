import {
  type AdvancedFilterType,
  type DateOption,
  type FilterState,
  type HierarchyFilter,
  type SelectedFilter,
  SourceType,
  TagTypes,
} from 'types';
import selectors from 'store/selectors';

// Format date to string
export const formatDateToString = (date: Date) =>
  date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

export const isDefaultCalendarOption = (quickDate: DateOption) =>
  quickDate?.label?.startsWith('Current') ||
  quickDate?.label?.startsWith('Month');

export type GetFilterBarDataReturnType = {
  FilterBarValues: Array<any>;
  WorkspaceId?: string;
  idWorkspace?: string;
  AdvancedFiltersGUID: string;
  HierarchyGUID: string;
  SelectedFavoriteId: string;
  Weight?: number;
  CacheVersion?: string;
};

/**
 * Given a tag type, filter and keysToExtractByTagType, it returns the keys to extract from the filter. This is useful to adapt the filter values to any format needed.
 */
const getKeysToExtractByTagType = ({
  tagType,
  filter,
  keysToExtractByTagType,
}: {
  tagType: TagTypes;
  filter: HierarchyFilter | AdvancedFilterType | DateOption;
  keysToExtractByTagType: { [key: string]: string[] };
}) => {
  const keys = keysToExtractByTagType?.[tagType] || [];
  return keys?.reduce((acc: any, key: any) => {
    acc[key] = filter[key as keyof typeof filter];
    return acc;
  }, {});
};

/**
 * @remarks
 * Helper that adapts selected filters to match API request format. And also return common data needed for it.
 * @returns {GetFilterBarDataReturnType | null}
 */

export function getFilterBarData(
  state: FilterState,
  keysToExtractByTagType: { [key: string]: string[] } = {}
): GetFilterBarDataReturnType {
  let filterBarValues: Array<any> = [];

  const hierarchyFilters = selectors.hierarchy.getSelectedFilters(state);
  const hierarchyFilterBarValues = Object.keys(hierarchyFilters || {})?.map(
    (key) => {
      const { filter, values } = hierarchyFilters[key] as SelectedFilter;
      const extractedFkeys = getKeysToExtractByTagType({
        tagType: TagTypes.Hierarchy,
        filter,
        keysToExtractByTagType,
      });
      return {
        DimName: null,
        Tag: TagTypes.Hierarchy,
        DisplayName: filter?.Label,
        AttributeGuid: '00000000-0000-0000-0000-000000000000',
        AttributeName: filter?.AttributeName,
        Op1: null,
        Op2: null,
        OpType: values?.length > 1 ? 'in' : '=',
        SubAttribute: filter?.SubAttributeName,
        Source: SourceType.Hierarchy,
        Values: values,
        ...(extractedFkeys || {}),
      };
    }
  );

  if (hierarchyFilterBarValues?.length > 0)
    filterBarValues = [...filterBarValues, ...hierarchyFilterBarValues];

  const advancedFilters = selectors.advanced.getSelectedFilters(state);
  if (advancedFilters?.length > 0) {
    const advancedFilterBarValues = (advancedFilters || [])
      ?.filter((filter) => filter?.Values)
      ?.map((filter) => {
        const extractedFkeys = getKeysToExtractByTagType({
          tagType: TagTypes.Advanced,
          filter,
          keysToExtractByTagType,
        });
        return {
          DimName: null as any,
          Tag: 'Advanced',
          DisplayName: filter?.Label,
          AttributeGuid: '00000000-0000-0000-0000-000000000000',
          AttributeName: filter.Name,
          Op1: null as any,
          Op2: null as any,
          // If user didn't select any SupportOperator we select the default
          OpType:
            filter?.OperatorOption?.value ||
            filter?.questionDetails?.SubAttributes?.[0]
              ?.SupportedOperators?.[0],
          SubAttribute:
            filter?.questionDetails?.SubAttributes?.[0]?.SubAttributeName,
          Source: SourceType.OtherFilters,
          Values: filter?.Values,
          ...(extractedFkeys || {}),
        };
      });
    filterBarValues = [...filterBarValues, ...advancedFilterBarValues];
  }

  const filterDate = selectors.calendar.getSelectedFilters(state);
  const AttributeName = selectors.calendar.getAttributeName(state);
  const isDefault = isDefaultCalendarOption(filterDate);
  if (filterDate && filterDate.startDate && !isDefault) {
    const { startDate, endDate = null } = filterDate;
    const startDateString = formatDateToString(startDate);
    const endDateString = endDate
      ? formatDateToString(endDate)
      : startDateString;
    const extractedFkeys = getKeysToExtractByTagType({
      tagType: TagTypes.Date,
      filter: filterDate,
      keysToExtractByTagType,
    });
    const dateFilterBarValue = {
      DimName: null as any,
      Tag: TagTypes.Date,
      DisplayName: null as any,
      AttributeGuid: '00000000-0000-0000-0000-000000000000',
      AttributeName,
      Op1: null as any,
      Op2: null as any,
      OpType: 'BETWEEN',
      Source: SourceType.Calendar,
      SubAttribute: 'Day',
      Values: [startDateString, endDateString],
      ...(extractedFkeys || {}),
    };
    filterBarValues = [...filterBarValues, dateFilterBarValue];
  }

  const WorkspaceId = selectors.getWorkspaceId(state);
  const AdvancedFiltersGUID = selectors.advanced.getAdvanceFiltersGuid(state);
  const HierarchyGUID = selectors.hierarchy.getHierarchyGuid(state);
  const SelectedFavoriteId = '-1';
  const Weight = selectors.getWeight(state);

  return {
    idWorkspace: WorkspaceId,
    FilterBarValues: filterBarValues,
    AdvancedFiltersGUID,
    HierarchyGUID,
    SelectedFavoriteId,
    Weight,
  };
}
