import React, { useState, useEffect } from 'react';
import './yAxisValueComponent.scss';

export interface OptionCheckboxProps {
  option: any;
  onChangeHandler: any;
  onSetGoalValuesChange: any;
  childComponent: any;
  customValues: any;
}

const defaultValue = {
  min: 0,
  max: 100,
};

const YAxisValueComponent: React.FC<any> = ({ onSetGoalValuesChange, customValues }) => {
  const [yAxisValue, setYAxisValue] = useState<any>(customValues || defaultValue);
  const [isInvalidMinMaxValue, setIsInvalidMinMaxValue] = useState<boolean>(false);

  useEffect(() => {
    if (yAxisValue) {
      onSetGoalValuesChange(yAxisValue);
    }
  }, [yAxisValue]);

  const handleMinValue = (event: { target: { value: any } }) => {
    const yAxisMinValue = parseInt(event.target.value);
    setIsInvalidMinMaxValue(yAxisMinValue < 0 || yAxisMinValue > yAxisValue?.max);
    setYAxisValue({
      ...yAxisValue,
      min: yAxisMinValue,
    });
  };

  const handleMaxValue = (event: { target: { value: any } }) => {
    const yAxisMaxValue = parseInt(event.target.value);
    setIsInvalidMinMaxValue(yAxisMaxValue < 0 || yAxisMaxValue < yAxisValue?.min);
    setYAxisValue({
      ...yAxisValue,
      max: yAxisMaxValue,
    });
  };

  const handleIncrementValue = (event: { target: { value: any } }) => {
    const yAxisIntervalValue = event.target.value;
    setYAxisValue({
      ...yAxisValue,
      interval: yAxisIntervalValue < 0 ? 0 : parseInt(yAxisIntervalValue),
      scale: true,
    });
  };

  return (
    <>
      <div className="diy-input-container">
        <div className="diy-input-container">
          <div className="diyYValuesLabel">
            <label className="diyMinimunLabel">Minimum</label>
            <label>Maximum</label>
          </div>
          <div className="diyYValuesInput">
            <input
              type="number"
              id="minValue"
              className="diyMinValue"
              value={yAxisValue?.min || 0}
              onChange={handleMinValue}
            />
            <input
              type="number"
              id="maxValue"
              className="diyMaxValue"
              value={yAxisValue?.max || 100}
              onChange={handleMaxValue}
            />
          </div>
          {isInvalidMinMaxValue ? (
            <span className="diy-required-field">Invalid Minimum or Maximum value</span>
          ) : null}
        </div>
        <div className="diy-input-container">
          <div className="diyYValuesLabel">
            <label className="diyIncrementsLabel">Increments</label>
          </div>
          <div className="diyYValuesInput">
            <input
              className="diyIncrementsValue"
              type="number"
              id="incrementsValue"
              value={yAxisValue?.interval}
              onChange={handleIncrementValue}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default YAxisValueComponent;
