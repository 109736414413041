import { DrawerCardModel } from 'models/Drawer';
import { Widget, WidgetCollection } from 'models/WidgetModel';
import { UrlConfig, GridProps } from './model';
import { defaultSelectedChartValues, defaultWidget, virtualWidget } from './constants';

// TODO: This is a temporary fix to get the app to compile.  We need to
//       refactor the initialGlobalState
//       REVIEW the property dataSourceTypesAvailable for avaliable datasources type .
export const initialGlobalState = {
  // eslint-disable-next-line prettier/prettier
  widgets: <Widget[]>[],
  widgetsList: <WidgetCollection[]>[],
  virtualWidget: virtualWidget,
  diyMode: false,
  customWidget: defaultWidget,
  diySave: false,
  drawerWidgets: <DrawerCardModel[]>[],
  widgetsAvailable: <any[]>[],
  editing: false,
  endpoints: <UrlConfig>{
    getDataSourceTypes: '/GuardRails/datasource-types',
    getChartTypes: '',
    getChartTypesModel: '',
    getData: '',
    getWidgetData: '',
  },
  isConsumer: false,
  searchFilter: '',
  dashboardView: <'chat' | 'survey' | 'text-analytics'>'survey',
  dataSourceType: <string[]>[],
  configDashboard: <GridProps>{
    dashboard: '',
    widgets: [],
    editMode: false,
    external: false,
    workspace: '',
    showDiyToolbox: false,
    useDiyQueryBuilder: false,
    devEnv: false,
    useDiyHierarchyFilters: false,
    useAdvancedFilters: false,
    viewId: 0,
    dataSourceTypesAvailable: [],
    urls: {
      getDataSourceTypes: '/GuardRails/datasource-types',
      getChartTypes: '',
      getChartTypesModel: '',
      getData: '',
      getWidgetData: '',
    },
  },
  selectedDiyChildChart: defaultSelectedChartValues,
};
