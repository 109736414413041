import { useState } from 'react';
import { useWidgetContext } from 'contexts/WidgetProvider';
import { appAssets } from 'constants/assets';
import { FaFileCsv, FaFileExcel } from 'react-icons/fa';
import './exportingoptions.css';

const ExportToFile = () => {
  const { setState } = useWidgetContext();
  const DownloadIcon = appAssets.icons.DOWNLOAD_ICON;

  const exportHandler = (val: string) => {
    setState({ action: val });
    toggleMenu();
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="meatball-menu calendar">
      <div className="meatball-icon" onClick={toggleMenu}>
        <DownloadIcon />
      </div>
      {isOpen && (
        <div className="menu-options">
          <ul>
            <li>
              <FaFileCsv size={18} onClick={() => exportHandler('EXPORT_TO_CSV')} />
            </li>
            <li>
              <FaFileExcel size={18} onClick={() => exportHandler('EXPORT_TO_XLSX')} />
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default ExportToFile;
