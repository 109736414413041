import React, { useEffect, useRef, useState } from 'react';
import './Grid.scss';
import { GridElementComponent, GridState } from 'models/GridModel';
import { Responsive, ResponsiveProps, Layout, WidthProvider } from 'react-grid-layout';
import { useAxios } from 'hooks/useAxios';
import { getDashboardById, getDataSourceTypes } from 'services/Torogoz/TogorozApi';
import { Widget } from 'models/WidgetModel';
import { cleanObject } from 'helpers/utilities';
import { v4 as uuidv4 } from 'uuid';
import CardWidget from 'components/CardWidget/CardWidget';
import Drawer from 'components/Drawer/Drawer';
import DiyDrawer from 'components/DiyDrawer/DiyDrawer';
import DiyModal from 'components/DiyModal/DiyModal';
import { appAssets } from 'constants/assets';
import { useStore } from 'store/useGlobalStore';
import { initialGlobalState } from 'store/initialGlobalState';
import { setSessionStorage } from 'helpers/sessionStorageHandler';
import { library } from 'components';

const ResponsiveGridLayout = WidthProvider(Responsive);

const initialGridState: GridState = {
  currentBreakpoint: 'lg',
  layouts: { lg: [] },
  components: null,
};

const defaultGridProps: ResponsiveProps = {
  className: 'layout',
  rowHeight: 10,
  cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
  breakpoints: { lg: 1160, md: 952, sm: 728, xs: 536, xxs: 410 },
  preventCollision: false,
  verticalCompact: true,
  allowOverlap: false,
  containerPadding: [0, 0],
  margin: [10, 10],
};

const Grid: React.FC = () => {
  const [grid, setGrid] = useState<GridState>(initialGridState);
  const [gridProps] = useState<ResponsiveProps>(defaultGridProps);
  const { response, axiosFetch } = useAxios();
  const { response: responseDt, axiosFetch: axiosFetchDT, error: errorAxiosDt } = useAxios();
  const EditIcon = appAssets.icons.EDIT_ICON;
  const {
    customWidget,
    diyMode,
    virtualWidget,
    diySave,
    editing,
    dashboard,
    defaultWidgets,
    editMode,
    external,
    showDiyToolbox,
    useDiyQueryBuilder,
    devEnv,
    urls,
    setState,
  } = useStore((state) => ({
    customWidget: state.customWidget,
    diyMode: state.diyMode,
    virtualWidget: state.virtualWidget,
    diySave: state.diySave,
    editing: state.editing,
    dashboard: state.configDashboard.dashboard,
    defaultWidgets: state.configDashboard.widgets,
    editMode: state.configDashboard.editMode,
    external: state.configDashboard.external,
    showDiyToolbox: state.configDashboard.showDiyToolbox,
    useDiyQueryBuilder: state.configDashboard.useDiyQueryBuilder,
    devEnv: state.configDashboard.devEnv,
    urls: state.configDashboard.urls,
    setState: state.setState,
  }));

  const mounted = useRef(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [dragging, setDragging] = useState(false);

  useEffect(() => {
    mounted.current = true;
    const getDataSource = async () => {
      await getDataSourceTypes(`${urls.getDataSourceTypes}/${dashboard}`, axiosFetchDT);
    };
    setState({ isConsumer: external });
    if (dashboard) {
      if (urls) setState({ endpoints: urls });
      const getDashboard = async () => {
        await getDashboardById(external, dashboard, axiosFetch);
      };
      getDataSource();
      getDashboard();
    }

    window.dispatchEvent(new Event('resize'));
    return () => {
      mounted.current = false;
    };
  }, [dashboard]);

  useEffect(() => {
    if (response?.widgets?.length && mounted.current) {
      initialize(response.widgets);
      setState({ widgets: response.widgets });
    }
  }, [response]);

  useEffect(() => {
    if (responseDt?.dataSourceType)
      setState({
        dataSourceType: responseDt?.dataSourceType ? responseDt?.dataSourceType : ['survey'],
      });
  }, [responseDt]);

  useEffect(() => {
    setState({
      dataSourceType: ['survey'],
    });
  }, [errorAxiosDt]);

  useEffect(() => {
    setState({ editing: Boolean(editMode) });
  }, [editMode]);

  useEffect(() => {
    updateWidgets(grid);
  }, [grid]);

  const initialize = (widgets: Widget[]) => {
    let gridItems: Layout[] = [];
    let components: GridElementComponent = {};

    for (const widget of widgets) {
      const config = {
        ...widget.config,
        isDraggable: undefined,
      };
      if (widget?.config) {
        gridItems = [...gridItems, config];
        const component: GridElementComponent = createComponent(widget);
        components = { ...components, ...component };
      }
    }
    const newGridState = { ...grid, layouts: { lg: gridItems }, components };
    setGrid(newGridState);
  };

  /**
   * Update grid layout when widgets position change
   */
  const onLayoutChange = (newlayout: Layout[]) => {
    if (!dragging) {
      let change = false;
      for (const ly of newlayout) {
        const current = grid.layouts[grid.currentBreakpoint].find(
          (layout) => layout.i.toString() === ly.i.toString()
        );
        if (current) {
          const lyA = JSON.stringify({ x: ly.x, y: ly.y });
          const lyB = JSON.stringify({ x: current.x, y: current.y });
          if (lyA !== lyB) {
            change = true;
          }
        }
      }
      if (change) {
        const newStateLayout = { ...grid };
        // Updating Layouts
        const nl: any = {};
        nl[newStateLayout.currentBreakpoint] = newlayout;
        newStateLayout.layouts = nl;
        setGrid(newStateLayout);
      }
    }
  };

  /**
   * This function is used to update widgets state when a widget is added to the grid
   */
  const onDropWidget = (newLayout: Layout[], item: Layout, e: any) => {
    setDragging(false);
    if (item) {
      // Creating new Widget
      const widgetDropped = JSON.parse(e.dataTransfer.getData('text/plain'));
      const newStateLayout = { ...grid };

      // Updating Layouts
      const nl: any = {};
      nl[newStateLayout.currentBreakpoint] = newLayout;
      newStateLayout.layouts = nl;

      //custom | prebuilt - Widget
      let newWidget: Widget;

      switch (widgetDropped.alias) {
        case library.QUERY_WIDGET:
        case library.CUSTOM_WIDGET:
          newWidget = createNewCustomWidget(
            item,
            widgetDropped,
            widgetDropped.alias === library.QUERY_WIDGET
          );
          setState({ customWidget: newWidget });
          break;
        default:
          newWidget = createNewWidget(item, widgetDropped);
      }

      const component: GridElementComponent = createComponent(newWidget);
      newStateLayout.components = { ...grid.components, ...component };
      // Updating components
      setGrid(newStateLayout);
    }
  };

  useEffect(() => {
    // Load modal editing windows only if diyMode is false and customWidget have config
    if (customWidget.alias !== '' && customWidget?.config && !diyMode) {
      setState({ diyMode: true });
    }
  }, [customWidget]);

  const createNewCustomWidget = (item: Layout, widgetDropped: any, hasQuery = false) => {
    const newCustomWidget: Widget = {
      id: uuidv4(),
      hasQuery: hasQuery,
      ...widgetDropped,
      config: {
        ...widgetDropped.config,
        ...cleanObject(item),
      },
    };

    return newCustomWidget;
  };

  useEffect(() => {
    if (diySave && customWidget.config) {
      const id = Number(customWidget.config.i);
      // Create new component to add
      const component: GridElementComponent = createComponent(customWidget);

      // Update Grid State
      setGrid((state) => {
        // Set new values for config object of widget dropped.
        const newGridState = { ...state };

        const indexToChange = newGridState.layouts[grid.currentBreakpoint].findIndex(
          (layout: any) => layout.i.toString() === id.toString()
        );

        if (indexToChange > -1) {
          const nl: any = {};
          const newLayout = [...newGridState.layouts[grid.currentBreakpoint]];
          newLayout[indexToChange] = customWidget.config;

          nl[newGridState.currentBreakpoint] = newLayout;
          newGridState.layouts = nl;

          const components = { ...newGridState.components };
          delete components[id];

          const newComponents = { ...components, ...component };

          newGridState.components = newComponents;
        }

        return newGridState;
      });

      setState({ diyMode: false, diySave: false });
      setState({ customWidget: initialGlobalState.customWidget });
    }
  }, [diySave]);

  const createComponent = (widget: Widget) => {
    const component: GridElementComponent = {};
    const index = Number(widget.config.i);
    component[index] = <CardWidget widget={widget} index={index} removeWidget={removeWidget} />;
    return component;
  };

  /*
   * Update layouts when a widget is resized
   */
  const handleResize = (layouts: Layout[]) => {
    const newGridLayout = { ...grid };
    // Updating Layouts
    const nl: any = {};
    nl[newGridLayout.currentBreakpoint] = layouts;
    newGridLayout.layouts = nl;
    setGrid(newGridLayout);
  };

  /*
   * Remove widget from grid layouts and grid components
   */
  const removeWidget = (id: number) => {
    setGrid((state) => {
      const newGridState = { ...state };

      const indexToRemove = newGridState.layouts[grid.currentBreakpoint].findIndex(
        (layout: any) => layout.i.toString() === id.toString()
      );

      if (indexToRemove > -1) {
        const nl: any = {};
        const newLayout = [...newGridState.layouts[grid.currentBreakpoint]];
        newLayout.splice(indexToRemove, 1);

        nl[newGridState.currentBreakpoint] = newLayout;
        newGridState.layouts = nl;

        const components = { ...newGridState.components };
        delete components[id];

        newGridState.components = components;
      }

      return newGridState;
    });
  };

  /*
   * Get the max i value on the layout config and return the value + 1
   * If there is no widgets on the grid returns 1.
   */
  const nextIndexToDrop = () => {
    if (!grid.layouts[grid.currentBreakpoint].length) return 1;

    const max = Math.max(...grid.layouts[grid.currentBreakpoint].map((o) => Number(o.i)));
    return max + 1;
  };

  const createNewWidget = (item: Layout, widgetDropped: any) => {
    const newWidget: Widget = {
      id: uuidv4(),
      ...widgetDropped,
      config: {
        ...widgetDropped.config,
        ...cleanObject(item),
      },
    };

    return newWidget;
  };

  const updateWidgets = (grid: GridState) => {
    const newWidgetState = [];

    const components = grid.components;
    for (const component in components) {
      const current = components[Number(component)];
      const newWidget = current?.props?.widget;

      const widgetLayout = grid.layouts[grid.currentBreakpoint].find(
        (layout: any) => layout.i.toString() === current.props.widget.config.i.toString()
      );

      newWidget.config = { ...newWidget.config, ...widgetLayout };
      newWidgetState.push(newWidget);
    }
    setState({ widgets: newWidgetState });
    setSessionStorage('widgets', newWidgetState);
  };
  return (
    <>
      {editing && diyMode ? (
        <>
          <DiyDrawer removeWidget={removeWidget} />
          <DiyModal
            modalOpen={diyMode}
            grid={grid}
            gridProps={defaultGridProps}
            gridContainer={containerRef.current}
            devEnv={devEnv}
          />
        </>
      ) : null}
      {editing && !diyMode ? (
        <Drawer
          defaultWidgets={defaultWidgets}
          showDiyToolbox={showDiyToolbox}
          useDiyQueryBuilder={useDiyQueryBuilder}
          devEnv={devEnv}
        />
      ) : null}
      {editing !== undefined ? (
        <div ref={containerRef}>
          <ResponsiveGridLayout
            {...gridProps}
            layouts={grid.layouts}
            onResizeStop={handleResize}
            onDrop={onDropWidget}
            onDragStop={() => setDragging(false)}
            onLayoutChange={onLayoutChange}
            measureBeforeMount={true}
            droppingItem={{ i: nextIndexToDrop().toString(), ...virtualWidget }}
            isDroppable={editing}
            isDraggable={editing}
            isResizable={editing}
          >
            {grid.layouts.lg.map((item: Layout) => {
              return (
                <div key={item.i} data-grid={item}>
                  {grid.components && grid.components[Number(item.i)]}
                </div>
              );
            })}
          </ResponsiveGridLayout>
        </div>
      ) : null}
      {devEnv && !editing ? (
        <div
          className="open-edit-mode"
          data-testid="showHideIcon"
          onClick={() => setState({ editing: true })}
        >
          <EditIcon />
        </div>
      ) : null}
    </>
  );
};

export default Grid;
