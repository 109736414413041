import React, { useEffect, useState } from 'react';
import './ChatTranscript.scss';
import { useAxios } from 'hooks/useAxios';
import { getDataFromSingleEndpoint } from 'services/Torogoz/TogorozApi';
import { useStore as useGlobalStore } from 'store/useGlobalStore';
import { appAssets } from 'constants/assets';
import { getFullFormat } from 'helpers/dateFormatters';

interface ChatTranscriptProps {
  setOpenTranscript: (value: boolean) => void;
  data: any;
}

const CloseIcon = appAssets.icons.CLOSE;

const ChatTranscript: React.FC<ChatTranscriptProps> = ({ setOpenTranscript, data }) => {
  const endpoints = useGlobalStore((state) => state.endpoints);
  const [sessionData, setSessionData] = useState<any>();
  const [systemMessages, setSystemMessages] = useState<any>();
  const [chatMessages, setChatMessages] = useState<any>();
  const { response: sessions, axiosFetch, loading } = useAxios();

  useEffect(() => {
    if (!data.id) return;
    const getData = async () => {
      await getDataFromSingleEndpoint(
        endpoints.getWidgetData,
        {
          session: data.id,
          mockEndpoint: 'data-session-transcript',
        },
        axiosFetch
      );
    };
    getData();
  }, [data]);

  useEffect(() => {
    if (!sessions) return;
    setSessionData(sessions.data);
  }, [sessions]);

  useEffect(() => {
    if (!sessions) return;
    const systemMessages = sessions.data.transcript.filter(
      (message: any) => message.emitterRole === 'System'
    );
    const chatMessages = sessions.data.transcript.filter(
      (message: any) => message.emitterRole !== 'System'
    );
    setSystemMessages(systemMessages);
    setChatMessages(chatMessages);
  }, [sessions]);

  return (
    <>
      {!loading && sessionData ? (
        <div className="chat-transcript">
          <div className="chat-transcript-header">
            <div className="transcript-info">
              <span>{sessionData.sessionId}</span>
              <span>{getFullFormat(sessionData.date)}</span>
            </div>
            <span className="close" onClick={() => setOpenTranscript(false)}>
              <CloseIcon fill="#575757" />
            </span>
          </div>
          <div className="chat-transcript-body">
            <div className="chat-transcript-summary">
              <span>Summary:</span>
              <p>{sessionData.summary}</p>
            </div>
            <div className="scroll">
              {systemMessages?.map((message: any, index: number) => {
                return (
                  <div key={index} className="chat-transcript-system-message">
                    <p>{message.verbatim}</p>
                    <span className="time">{message.chatFields.msgTime}</span>
                  </div>
                );
              })}

              {chatMessages?.map((message: any, index: number) => {
                return (
                  <div
                    className={`chat-transcript-message ${
                      message.emitterRole === 'Agent' ? 'agent' : 'customer'
                    }`}
                    key={index}
                  >
                    <div className="details">
                      <div className="message">
                        <span className="thumbnail">
                          {message.emitterRole === 'Agent' ? 'A' : 'C'}
                        </span>
                        <p>{message.verbatim}</p>
                      </div>
                    </div>
                    <span className="time">{message.chatFields.msgTime}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ChatTranscript;
