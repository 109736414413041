import React from 'react';
import { appAssets } from 'constants/assets';
import './filteringDatePicker.css';
import { getDateByPeriodByType } from 'helpers/getTimePeriod';

export interface FilteringDatePickerProps {
  inputTitle: string;
  handleOpenMoldal: any;
  dateSelection: any;
  currentSelection: any;
}

const CalendarIcon = appAssets.icons.CALENDAR_FILTER_DRAWER;

const currentDate = new Date();
const monthToDateStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

const FilteringDatePicker: React.FC<FilteringDatePickerProps> = ({
  inputTitle,
  handleOpenMoldal,
  dateSelection,
  currentSelection,
}) => {
  dateSelection =
    Object.keys(dateSelection).length !== 0 ? dateSelection : currentSelection.DateFilter;
  return (
    <div className="diy-input-container">
      <div className="diy-date-range-container diy-calendar-input">
        <div style={{ display: 'flex', paddingRight: '10px' }}>
          {currentSelection.DateFilter &&
          currentSelection?.DateFilter?.Type.toString() !== 'CustomRange' ? (
            <>
              <label>{getDateByPeriodByType(currentSelection.DateFilter.Type)}</label>
            </>
          ) : (
            <>
              <label>
                {dateSelection ? dateSelection.StartDate : monthToDateStart.toLocaleDateString()}{' '}
              </label>{' '}
              -{' '}
              <label>
                {dateSelection ? dateSelection.EndDate : currentDate.toLocaleDateString()}
              </label>
            </>
          )}
        </div>
        <div className="diy-icon-container">
          <div className="diy-filtering-vertical-line"></div>
          <CalendarIcon className="diy-filtering-icon" onClick={handleOpenMoldal}></CalendarIcon>
        </div>
      </div>
      <div className="diy-label">{inputTitle}</div>
    </div>
  );
};

export default FilteringDatePicker;
