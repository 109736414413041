import React, { useEffect, useRef, useState } from 'react';
import ReactECharts, { EChartsOption } from 'echarts-for-react';
import { BaseWidget } from 'models/WidgetModel';
import { tooltip } from 'constants/chartOption';
import './HorizontalSingleSentimentStackedBarChart.scss';

interface horizontalSingleSentimentStackedBarChartProps extends BaseWidget {
  colors: string[];
}

const HorizontalSingleSentimentStackedBarChart: React.FC<
  horizontalSingleSentimentStackedBarChartProps
> = ({ response, ...props }) => {
  const [series, setSeries] = useState<any>([]);
  const [options, setOptions] = useState<any>();
  const [colorPalette, setColorPallete] = useState<string[]>();
  const [isPercentage, setIsPercentage] = useState<boolean>(false);
  const { width, height } = props;
  const seriesColor = ['#ba050a', '#fcbe23', '#99d15a'];

  const colorsRef = useRef<string[]>();
  colorsRef.current = colorPalette;

  useEffect(() => {
    if (response) {
      setSeries(response.data.series);
      setColorPallete(seriesColor);
      setIsPercentage(response.data.isPercentage);
    }
  }, [response]);

  const horizontalStackedSeriesOption = (value: any) => {
    return {
      type: 'bar',
      stack: 'total',
      itemStyle: {
        borderColor: value >= 1 ? 'transparent' : null,
        borderRadius: 4,
        borderWidth: 2.5,
      },
    };
  };
  const getSeries = () => {
    if (series && series?.length) {
      const x = series.map((s: any) => {
        return {
          ...horizontalStackedSeriesOption(s.value),
          name: s.label,
          data: [s.value],
        };
      });
      return x;
    }
  };

  useEffect(() => {
    const optionsObj: EChartsOption = initOptions();
    setOptions(optionsObj);
  }, [series, colorPalette]);

  const initOptions = () => {
    if (!colorsRef.current?.length) return {};
    return {
      tooltip: {
        ...tooltip,
        formatter: `<strong>{a}:</strong> {c}${isPercentage ? '%' : ''}`,
      },

      grid: {
        //show: false,
        show: false,
        left: '0',
        right: '0',
        top: '0',
        bottom: '0',
      },
      xAxis: {
        type: 'value',
        show: false,
      },
      yAxis: {
        type: 'category',
        show: false,
      },
      color: colorsRef.current,
      series: getSeries(),
    };
  };

  return options && series?.length ? (
    <>
      <div className="horizontal-sentiment-stacked-bar">
        <ReactECharts opts={{ renderer: 'svg', width, height }} option={options} />
      </div>
    </>
  ) : (
    <></>
  );
};

export default HorizontalSingleSentimentStackedBarChart;
